import { h, resolveComponent } from 'vue'

const Register = () => import("@/app/views/register/views/Register");
const Mensaje = () => import("@/app/views/register/views/Mensaje");


const registerRoutes = [
  {
    path: "/registro",
    name: "Register",
    component: Register,
    meta: {
      auth: false
    }
  },
  {
    path: "/centro-creado",
    name: "Mensaje",
    component: Mensaje,
    meta: {
      auth: false
    }
  },

];


export default registerRoutes;
